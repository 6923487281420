<template>
  <div class="container">
    <h1>Чаты (Забронированные предложения)</h1>
    <div v-if="chats">
      <b-link
        :to="'/chats/' + chat._id"
        class="chats-item"
        v-for="chat in orderedChats"
        :key="chat._id"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            {{ usersChatNames.get(chat.client) }}
          </h5>
          <small>{{ chat.updatedAt | moment("DD.MM.YY H:mm") }}</small>
        </div>
        <div class="row">
          <div class="col">
            <div v-if="chat.lastMessage">
              {{ chat.lastMessage["text"] }}
            </div>
          </div>
          <div class="col-auto">
            <b-badge variant="success" pill v-if="chat.unreadMessageCount">
              {{ chat.unreadMessageCount }}
            </b-badge>
          </div>
        </div>
      </b-link>
    </div>
    <div v-else>
      <p>Чатов нет</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Chats",
  data() {
    return {
      chats: null,
      usersChatNames: new Map(),
    };
  },
  computed: {
    orderedChats() {
      let chats = [...this.chats];
      return chats.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
    },
  },
  components: {},
  methods: {
    async getUsersChatNames() {
      let names = new Map();

      await Promise.all(
        this.chats.map(async (element) => {
          try {
            let user = (
              await axios.get(
                `https://vp.bw2api.ru/api/v1/crud/user?auth=${element.client}`
              )
            ).data;

            names.set(element.client, user[0].name);
          } catch (error) {
            console.log("error", error);
          }
        })
      );

      this.usersChatNames = names;
    },
    async loadChats() {
      this.chats = (
        await axios.get(`https://vp.bw2api.ru/api/v1/reserved_offer/chat`)
      ).data;
    },
  },
  async mounted() {
    await this.loadChats();
    await this.getUsersChatNames();
  },
};
</script>
